span.link-primary {
  cursor: pointer;
}

span.link-success {
  cursor: pointer;
}

span.link-danger {
  cursor: pointer;
}

.user-tab-wrapper {
  background-color: transparent;
  border-radius: 30px;

  .user-tab {
    width: 100%;
    border-radius: 30px;
    margin: 10px 0;
    padding: 0 20px 0 10px;
    display: flex;
    justify-content: right;
    gap: 20px;
    transform: "translate(0, 0)";


    .user-tab-content {
      width: 100%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
    }

    .user-tab-move {
      width: 40px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


.hub-box {
  background-color: #556EE633;
  border-radius: 30px;
  padding: 20px;


  .hub-box-bin {
    border-radius: 20px;
    margin: 10px 0;
    min-height: 200px;
    padding-bottom: 10px;

    .hub-box-bin-head {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 10px 5px 0 5px;

      display: flex;
      justify-content: space-between;
      font-weight: bold;

      background-color: #F8F9FA;
      border-bottom: #EFF2F7 solid 2px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.users-basket {
  min-height: 80%;
  width: 100%;
}


.jfrgbA {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.jzGaAN {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.jYPiPR {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cLRkKo {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.avatar-upload-circle {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 2px dashed #ced4da;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #74788d;
  cursor: pointer;
}

.Toastify__toast-body {
  font-family: "Poppins", sans-serif !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 0.8125rem;
  letter-spacing: .01rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 48px;
  margin-left: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  line-height: 1.5;
  height: 35px;
  width: 100%;
  outline: none
}

.react-tel-input .form-control.invalid-number {
  border-color: #f46a6a;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f46a6a%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f46a6a%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
  background-color: #fff;

}

.react-tel-input .form-control.invalid-number:focus {
  border-color: #f46a6a;
  box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, 0.25);
  background-color: #fff;
}

.react-tel-input .flag-dropdown.invalid-number {
  border-color: #f46a6a;
}
